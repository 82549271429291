/**
 * Legacy Answers Endpoint.
 *
 * This service handles the communication with the legacy (v1) answers API.
 * Returns all the information the API has in the Customer Input Response (CIR) as it relates to the answers a user provided
 *
 * if you want to get the full CIR object, you also need to use the users service.
 *
 * This should not be interacted with directly - should use another service or NgRx Effects.
 */
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AppContext } from '../../../../app-config';
import { LegacyApiPagesCir } from '../quote-pages/models';

@Injectable({
  providedIn: 'root',
})
export class LegacyAnswersApiService {
  @AppContext() private _appContext!: AppContext;
  private httpClient = inject(HttpClient);

  /**
   * Gets url based on app context
   */
  private get url() {
    return `${this._appContext.apiRoot.quoteV1}/answers`;
  }

  /**
   * Simple GET request for Customer Input Response (CIR)
   * @returns
   */
  public getAnswersApi() {
    return this.httpClient.get<LegacyApiPagesCir>(this.url);
  }
}
