/**
 ********************
 * Response Mapping - Utility Functions
 ********************
 */

/**
 * removes country code
 * @example: +13333333333 to 3333333333
 */
export function translateLegacyPhone(phoneNumber: string | undefined | null | number): string | undefined {
  if (typeof phoneNumber === 'string') {
    if (phoneNumber && phoneNumber.startsWith('+1')) {
      return phoneNumber.slice(2);
    }
    return phoneNumber;
  }
  if (typeof phoneNumber === 'number') {
    return `${phoneNumber}`;
  }
  return undefined;
}

/**
 * Translates the default null from the API as undefined for the UI
 */
export function translateApiNullToUndefined<T>(value: T | null): T | undefined {
  if (value === null) {
    return undefined;
  }
  return value;
}

/**
 * Translates a LegacyNumber into a number or undefined
 * @example: '0' to 0
 */
export function translateLegacyNumber(num: number | string | null | undefined): number | undefined {
  if (typeof num === 'number') {
    return num;
  }
  if (typeof num === 'string') {
    const parsed = parseFloat(num);
    return Number.isNaN(parsed) ? undefined : parsed;
  }
  return undefined;
}

export function translateLegacyStringBoolean(value: 'true' | 'false' | boolean | null | undefined): boolean | undefined {
  if (value === null) {
    return undefined;
  }
  if (value === 'true' || value === true) {
    return true;
  }
  return value === 'false' || value === false ? false : undefined;
}
