/**
 * Quote Draft Entry Service
 * - should be used to start a new quote.
 *
 * !! Important, this should not persist.  The service needs to be destroyed when the entry component using it is destroyed.
 * This is because it holds state and will continually redirecting users if not wiped out.
 */
import { inject, Injectable, signal } from '@angular/core';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { Router } from '@angular/router';
import { NgKLoggerService } from '@kin/ngk-logger';
import { catchError, firstValueFrom, map, of, switchMap } from 'rxjs';
import { DecodedLegacyApiAddressResponse, LegacyCreateCirService } from '../../../common/services/legacy/create-cir/create-cir.service';
import { LegacyApiPagesResponse, LegacyPagesApiService } from '../../../common/services/legacy/quote-pages';
import { QuoteDraftAddress, QuoteDraftStore } from '../../../global-store/quote-draft';
import { QuoteFormOptionsStore } from '../../../global-store/quote-form-options';
import { EntryType } from '../../../global-store/user/user.model';
import { UserStore } from '../../../global-store/user/user.store';
import { QuoteDraftSyncService } from '../sync/sync.service';

export const LOCAL_STORAGE_DRAFT_TOKEN_KEY = 'draftToken';

export type QuoteDraftEntryContext = undefined | 'new' | 'resume' | 'click-lead';

export type QuoteDraftEntryRouteTo = { type: 'redirect' | 'form'; route: string };

@Injectable()
export class QuoteDraftEntryService {
  private quoteStore = inject(QuoteDraftStore);
  private userStore = inject(UserStore);
  private optionsStore = inject(QuoteFormOptionsStore);
  private legacyCreateCirService = inject(LegacyCreateCirService);
  private legacyPagesApiService = inject(LegacyPagesApiService);
  private syncService = inject(QuoteDraftSyncService);
  private logger = inject(NgKLoggerService);
  public router = inject(Router);
  public localStorage = inject(LOCAL_STORAGE);

  public isLoadingSignal = signal<boolean>(false);
  public entryRouteSignal = signal<QuoteDraftEntryRouteTo | undefined>(undefined);

  /**
   * Click Leads Entry Point - used for incoming click leads
   * @param redirectUrl = path to redirect the user to
   */
  public clickLeadsEntry(redirectUrl: string) {
    if (!redirectUrl) {
      this.entryRouteSignal.set({ type: 'redirect', route: '/resume-expired-token' });
      this.logger.error({
        context: 'QuoteDraftEntryService.clickLeadsEntry',
        message: `No redirect URL passed`,
        priority: 'P4',
      });
      return;
    }

    this.isLoadingSignal.set(true);

    this.userStore.setEntryType('click-lead');
    this.userStore.startQuoting();

    const match = redirectUrl?.match(/[?&]token=([^&]*)/);

    if (match) {
      const [, token] = match;
      this.localStorage.setItem(LOCAL_STORAGE_DRAFT_TOKEN_KEY, token);
      this.quoteStore.patchStore({ draftToken: token });
    } else {
      this.logger.error({
        context: 'QuoteDraftEntryService.clickLeadsEntry',
        message: `No token found in query params for redirect URL: ${redirectUrl}`,
        priority: 'P3',
      });
      this.userStore.endQuoting();
      /**
       * @todo update to handle a redirect
       * this.entryRouteSignal.set({ type: 'redirect', route: '/resume-expired-token' });
      this.isLoadingSignal.set(false);
       */
    }

    firstValueFrom(this.syncService.restoreStoreForClickLead())
      .then((quoteToRestore) => {
        this.quoteStore.patchStore(quoteToRestore);
      })
      .catch((error) => {
        this.logger.error({
          context: 'QuoteDraftEntryService.clickLeadsEntry',
          message: `unable to restore quote: ${JSON.stringify(error)}`,
          priority: 'P4',
        });
        this.userStore.endQuoting();
        this.entryRouteSignal.set({ type: 'redirect', route: 'restore-failure' });
      })
      .finally(() => {
        this.entryRouteSignal.set({ type: 'redirect', route: redirectUrl });
        this.isLoadingSignal.set(false);
      });
  }

  /**
   * Restore Quote Draft Entry Point - used for resuming
   * @param token - token to use for the restore request
   */
  public restoreQuoteDraftEntry(providedToken: string, entryType: EntryType = 'resume') {
    if (providedToken) {
      this.localStorage.setItem(LOCAL_STORAGE_DRAFT_TOKEN_KEY, providedToken ?? '');
      this.quoteStore.patchStore({ draftToken: providedToken });
    } else {
      this.quoteStore.reset(); // reset since their session is expired.  This will also wipe the token from local storage
      this.userStore.endQuoting();
      this.entryRouteSignal.set({ type: 'redirect', route: '/resume-expired-token' });
      return;
    }

    // update the status of this service
    this.isLoadingSignal.set(true);

    // update user entry details & set status to quoting
    this.userStore.setEntryType(entryType);
    this.userStore.startQuoting();

    // get data from API to restore
    firstValueFrom(this.syncService.restoreStore())
      .then((quoteToRestore) => {
        const { entryRoute, restoredState, pageResponse } = quoteToRestore;
        if (restoredState) {
          this.quoteStore.patchStore(quoteToRestore.restoredState);
        }

        const { type, route } = entryRoute;

        if (type === 'form' && pageResponse?.page) {
          const pg = pageResponse.page;
          const formOptions = this.optionsStore.getLegacyOptionsForForm(pg.path, pg.components, this.logger);
          this.optionsStore.updateOptionsForFormByLegacyPath(pg.path, formOptions, this.logger);
        }

        if (type && route) {
          this.entryRouteSignal.set(entryRoute);
        } else {
          this.entryRouteSignal.set({ type: 'redirect', route: '404' });
        }
      })
      .catch((error) => {
        this.logger.error({
          context: 'restoreQuoteDraftEntry',
          message: `unable to restore quote: ${JSON.stringify(error)}`,
          priority: 'P4',
        });
        this.userStore.endQuoting();
        this.entryRouteSignal.set({ type: 'redirect', route: 'resume-expired-token' });
      })
      .finally(() => {
        this.isLoadingSignal.set(false);
      });
  }

  /**
   * Method for starting a new quote draft for a "new" user type
   * - no state injection
   * - returns nothing, listen to entryStatusSignal and entryRouteSignal
   * @param address - The address for the quote draft.
   * @returns An observable that emits the result of the CIR creation process.
   */
  public newQuoteDraftEntry(address: QuoteDraftAddress, localStorageToken?: string, isClickLead: boolean = false): void {
    // Update the status of this service
    this.isLoadingSignal.set(true);

    // update state to Quoting
    this.userStore.startQuoting();

    if (localStorageToken) {
      this.quoteStore.patchStore({ draftToken: localStorageToken });
      // Update the user entry type: if there is a token already in local storage, the user is either
      // 1. a click lead
      // 2. resuming a quote.
      // If the user is a click lead, the Start component will have a `click_id` query param and will inform the quoteDraftEntryService
      // that the user is a click lead. Otherwise, the user is resuming a quote.
      if (isClickLead) {
        this.userStore.setEntryType('click-lead');
      } else {
        this.userStore.setEntryType('resume');
      }
    } else {
      this.userStore.setEntryType('new');
    }

    // using firstValueFrom so we don't have to subscribe.
    firstValueFrom(
      this.createNewCIR(address).pipe(
        switchMap((newCirRes) => {
          // first handle the CIR creation response
          const shouldRouteAfterCirRes = this.handleCirCreationResponse(newCirRes, address);
          if (shouldRouteAfterCirRes) {
            this.isLoadingSignal.set(false);
            this.entryRouteSignal.set(shouldRouteAfterCirRes);
            return of(true);
          }

          // assuming a non-redirect response, we start polling for a good pages response
          return this.getNextPage().pipe(
            map((nextPgRes) => {
              this.entryRouteSignal.set(this.handlePollingResponse(nextPgRes));
              this.isLoadingSignal.set(false);
              return true;
            }),
          );
        }),
        catchError(() => {
          this.logger.error({ message: 'unknown API error', priority: 'P3', context: 'QuoteDraftEntryService' });
          this.userStore.endQuoting();
          this.isLoadingSignal.set(false);
          this.entryRouteSignal.set({ type: 'redirect', route: '404' });
          // return does not matter, we simply need to finalize the observable stream
          return of(true);
        }),
      ),
    );
  }

  /**
   * Handles the CIR Creation to populate the store
   * @returns undefined = continue processing, QuoteDraftEntryRouteTo = stop and redirect
   */
  private handleCirCreationResponse(response: DecodedLegacyApiAddressResponse, address: QuoteDraftAddress): QuoteDraftEntryRouteTo | undefined {
    if (response.redirect_to) {
      return { type: 'redirect', route: response.redirect_to };
    }
    const token = response.authentication?.token;
    this.localStorage.setItem(LOCAL_STORAGE_DRAFT_TOKEN_KEY, token ?? '');

    this.quoteStore.patchStore({
      draftToken: token,
      cirId: response.authentication?.decoded?.data.customer_response_id,
      address,
    });
    if (response.resumable) {
      return { type: 'redirect', route: 'resume-my-quote/resumable' };
    }
    return undefined;
  }

  /**
   * Polling used to search for next page
   */
  private handlePollingResponse(response: LegacyApiPagesResponse): QuoteDraftEntryRouteTo {
    // log unexpected condition
    if (response.page?.path !== '/quote/about-you') {
      this.logger.event({
        metaData: {
          context: 'QuoteDraftEntryService',
          message: `Pages API response returned unexpected page--not about-you page`,
          data: response,
        },
        name: 'unexpected-api-response',
      });
    }

    // handle expected conditions
    if (response.page?.path) {
      return { type: 'form', route: response.page?.path };
    }
    if (response.redirect_to) {
      return { type: 'redirect', route: response.redirect_to };
    }

    // handle unexpected parm routing
    this.logger.error({ message: `Pages API response did not include a known response: ${JSON.stringify(response)}`, context: 'legacyCreateCirService.getCIR', priority: 'P3' });
    this.userStore.endQuoting();
    return { type: 'redirect', route: '404' };
  }

  /**
   * Creates a new CIR (Customer Input Response) based on the provided address.
   * @param address - The address for the quote draft.
   * @returns An observable that emits the result of the CIR creation process.
   */
  private createNewCIR(address: QuoteDraftAddress) {
    const displayAddress = `${address.street} ${address.city}, ${address.state} ${address.zipcode}`;
    return this.legacyCreateCirService.getCIR({ street_address: displayAddress, unit_number: address.unit ?? '' });
  }

  /**
   * Polls for the Pages API for a non-loading response.
   * @returns An observable that emits the result of the next page polling request (will emit once or a timeout).
   */
  private getNextPage() {
    return this.legacyPagesApiService.pollForNextPage();
  }
}
