/**
 * Legacy Customer Input Response Endpoint.
 *
 * This service handles the communication with the legacy (v1) customer_input_responses API.
 * Returns identifying information for the CIR object (not the object itself)
 *
 * This should not be interacted with directly - should use another service or NgRx Effects.
 */
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AppContext } from '../../../../app-config';

export type LegacyApiUsersResponse = {
  address: string;
  age: number;
  birthday: string; // format YYYY-MM-DD
  brite_verify_status: unknown;
  city: string;
  county_fips: string;
  county: string;
  created_at: number;
  crm_account_id: number;
  crmId: number;
  email: string;
  emailAsSHA: string;
  first_name: string;
  id: string;
  kin_id: number;
  kin_segment_user_id: string;
  kintranet_url: string;
  last_name: string;
  lead_source: unknown;
  locale: unknown;
  name: string;
  phone: string;
  product: string;
  property_id: number;
  sign_in_count: number;
  state: string;
  unit_number: string;
  userId: number;
  zip: string;
};

@Injectable({
  providedIn: 'root',
})
export class LegacyUsersApiService {
  @AppContext() private _appContext!: AppContext;
  private httpClient = inject(HttpClient);

  /**
   * Gets url based on app context
   */
  private get url() {
    return `${this._appContext.apiRoot.quoteV1}/users`;
  }

  /**
   * Simple GET request for Customer Input Response (CIR)
   * @returns
   */
  public getUserFromApi() {
    return this.httpClient.get<LegacyApiUsersResponse>(this.url);
  }
}
