import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { decodeCirJwt, JwtCirPayload } from './utils/decode-cir-jwt';
import { AppContext } from '../../../../app-config';

export type LegacyApiAddress = {
  street_address: string;
  unit_number?: string;
};

type RedirectTo = { redirect_to?: string };

export type LegacyApiAddressResponse = {
  address?: {
    id: number;
    verified: boolean;
    latitude: number;
    longitude: number;
    standardized_address: string;
    state: string;
    state_full: string;
  };
  authentication?: {
    token: string;
    expires: number;
  };
  resumable?: boolean;
} & RedirectTo;

export type DecodedAuthentication = {
  token: string;
  expires: number;
  decoded: JwtCirPayload | undefined;
};

export type DecodedLegacyApiAddressResponse = RedirectTo &
  Omit<LegacyApiAddressResponse, 'authentication'> & {
    authentication?: DecodedAuthentication;
  };

@Injectable({
  providedIn: 'root',
})
export class LegacyCreateCirService {
  @AppContext() private _appContext!: AppContext;

  private httpClient = inject(HttpClient);

  private get url() {
    return `${this._appContext.apiRoot.quoteV1}/addresses`;
  }

  getCIR(address: LegacyApiAddress): Observable<DecodedLegacyApiAddressResponse> {
    if (!address.unit_number) {
      address.unit_number = '';
    }
    return this.httpClient.post(this.url, address).pipe(
      map((response: LegacyApiAddressResponse) => {
        if (response.redirect_to) {
          return response as RedirectTo;
        }
        if (response.authentication) {
          const decodedToken = decodeCirJwt(response.authentication.token);
          return {
            ...response,
            ...{
              authentication: {
                decoded: decodedToken ?? undefined,
                token: response.authentication?.token,
                expires: response.authentication?.expires,
              },
            },
          };
        }
        return { redirect_to: '404' } as RedirectTo;
      }),
    );
  }
}
